import { Badge } from "@/block-system/brickz/components/ui/badge";
import { Button } from "@/block-system/brickz/components/ui/Button";
import {
  Popover,
  PopoverTrigger,
} from "@/block-system/brickz/components/ui/popover";
import { cn } from "@/block-system/brickz/lib/utils";
import {
  Check as CheckIcon,
  ChevronDown as ChevronDownIcon,
  X as XIcon,
} from "lucide-react";
import { forwardRef, Fragment, useId, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { PopoverContent } from "./popover";

export type Option = {
  label: string;
  value: string;
};

export type DropdownSelectValue =
  | string
  | string[]
  | null
  | { label: string; value: string };

export type Props = {
  value?: DropdownSelectValue;
  placeholder?: string;
  options: Option[];
  multiSelect?: boolean;
  searchable?: boolean;
  isErrored?: boolean;
  onChange?: (newValue: DropdownSelectValue) => void;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  id?: string;
  menuAriaLabel?: string;
  disabled?: boolean;
};

export const DropdownSelect = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value = null,
      placeholder,
      options,
      multiSelect,
      searchable = true,
      isErrored,
      onChange,
      isDisabled,
      className,
      isRequired,
      id: providedId,
      menuAriaLabel,
      // Added to disable showing the clear selection button when disabled
      disabled,
    },
    ref
  ) => {
    const generatedId = useId();
    const id = providedId ?? generatedId;

    return (
      <div>
        {multiSelect ? (
          <DropdownMultiSelect
            id={id}
            options={options}
            /**
             * We have to parse the value here to ensure we pass the right value type into the component.
             *
             * This is essential in situations where user toggles the "Allow multiple selections" toggle.
             * Toggling from "off" to "on" switches the `multiSelect` value to be `true`,
             * but it will NOT update the `value` we get as a prop to this component.
             *
             * This is because the configuration panel is disjointed from the place we render this component – the form block.
             * The form block uses `useForm` with `defaultValues`. These `defaultValues` are cached first time component mounts.
             * When the toggle changes, we have no way to "reset" those `defaultValues`.
             *
             */
            value={parseMultiSelectValue({ value })}
            onChange={(newValues) => {
              onChange?.(newValues);
            }}
            isErrored={isErrored}
            isDisabled={isDisabled || disabled}
            className={className}
            placeholder={placeholder}
            ref={ref}
            searchable={searchable}
            menuAriaLabel={menuAriaLabel}
          />
        ) : (
          <DropdownSingleSelect
            searchable={searchable}
            id={id}
            options={options}
            /**
             * We have to parse the value here to ensure we pass the right value type into the component.
             *
             * This is essential in situations where user toggles the "Allow multiple selections" toggle.
             * Toggling from "on" to "of" switches the `multiSelect` value to be `false`,
             * but it will NOT update the `value` we get as a prop to this component.
             *
             * This is because the configuration panel is disjointed from the place we render this component – the form block.
             * The form block uses `useForm` with `defaultValues`. These `defaultValues` are cached first time component mounts.
             * When the toggle changes, we have no way to "reset" those `defaultValues`.
             *
             */
            value={parseSingleSelectValue({ value })}
            onChange={onChange}
            placeholder={placeholder}
            isRequired={isRequired}
            isErrored={isErrored}
            isDisabled={isDisabled || disabled}
            className={className}
            ref={ref}
            menuAriaLabel={menuAriaLabel}
          />
        )}
      </div>
    );
  }
);

DropdownSelect.displayName = "DropdownSelect";

function parseMultiSelectValue({ value }: { value: Props["value"] }) {
  if (Array.isArray(value)) {
    return value;
  }

  if (value == null) {
    return [];
  }

  if (typeof value === "string") {
    if (value.trim() === "") {
      return [];
    }

    return [value];
  }

  if (typeof value === "object" && value !== null) {
    return [value.label];
  }

  return [];
}

function parseSingleSelectValue({ value }: { value: Props["value"] }) {
  /**
   * We have to use `""` here as fallback.
   * This will indicate to `SelectValue` to display the `placeholder` prop.
   */
  const emptySelectValue = "";

  if (value == null) {
    return emptySelectValue;
  }

  if (Array.isArray(value)) {
    const firstValue = value[0];
    if (!firstValue || firstValue.trim() === "") {
      return emptySelectValue;
    }
    return firstValue;
  }

  if (typeof value === "object" && value !== null) {
    const labelValue = value.label;
    if (!labelValue || labelValue.trim() === "") {
      return emptySelectValue;
    }
    return labelValue;
  }

  if (typeof value === "string") {
    if (value.trim() === "") {
      return emptySelectValue;
    }
    return value;
  }

  return emptySelectValue;
}
interface MultiSelectProps {
  options: { label: string; value: string }[];
  value: string[];
  onChange?: (newValue: string[]) => void;
  className?: string;
  isDisabled?: boolean;
  searchable: boolean;
  isErrored?: boolean;
  placeholder?: string;
  id: string;
  ariaLabel?: string;
  name?: string;
  menuAriaLabel?: string;
}

const DropdownMultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      value: values,
      onChange,
      className,
      isDisabled,
      isErrored,
      searchable = true,
      placeholder,
      ariaLabel,
      id,
      name,
      menuAriaLabel,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const selectedOptions = options.filter((option) => {
      return values.includes(option.value);
    });

    const handleDeselect = (option: Option) => {
      const newValues = values.filter((value) => value !== option.value);
      onChange?.(newValues);
    };

    const handleSelect = (option: Option) => {
      const isAddingItem = !values.includes(option.value);
      if (isAddingItem) {
        onChange?.([...values, option.value]);
      } else {
        onChange?.(values.filter((i) => i !== option.value));
      }
    };

    return (
      <Fragment>
        <Popover open={open} onOpenChange={setOpen} modal={false}>
          <PopoverTrigger asChild>
            <Button
              ref={ref}
              id={id}
              name={name}
              variant="outline"
              role="combobox"
              aria-label={ariaLabel}
              disabled={isDisabled}
              aria-expanded={open}
              className={cn(
                "w-full",
                "bg-transparent",
                "hover:bg-transparent",
                "border border-solid border-input",
                "data-[errored=true]:border-destructive",
                "disabled:bg-transparent",
                "px-3 py-2.5",
                {
                  /**
                   * Make sure to expand the height of the button when we render multiple badges.
                   */
                  "h-fit": values.length > 0,
                  "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
                    isDisabled,
                  "border-destructive": isErrored,
                }
              )}
              onClick={() => setOpen(!open)}
            >
              <div className={"flex w-full"}>
                <DropdownMultiSelectBadgeList
                  selectedOptions={selectedOptions}
                  handleDeselect={handleDeselect}
                  placeholder={placeholder}
                  isDisabled={isDisabled}
                />
                <ChevronDownIcon
                  /**
                   * Used in end-to-end tests to trigger the dropdown menu.
                   *
                   * We can't click on the `Button` as it might contain a badge.
                   * If it contains a badge, and Playwright clicks on the badge, the menu won't open.
                   * Instead, we will delete the badge, which is undesirable.
                   *
                   * This is why we are using a separate `data-testid` here.
                   */
                  data-testid="dropdown-trigger"
                  className="ml-auto h-4 w-4 text-foreground"
                />
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full border-none p-0" align={"start"}>
            <DropdownMultiSelectOptions
              searchable={searchable}
              className={className}
              options={options}
              handleSelect={handleSelect}
              value={values}
              menuAriaLabel={menuAriaLabel}
            />
          </PopoverContent>
        </Popover>
      </Fragment>
    );
  }
);

function DropdownMultiSelectBadgeList({
  selectedOptions,
  handleDeselect,
  placeholder,
  isDisabled,
}: {
  selectedOptions: Option[];
  handleDeselect: (option: Option) => void;
  placeholder: string | undefined;
  isDisabled?: boolean;
}) {
  const hasItems = selectedOptions.length > 0;
  const hasPlaceholder = placeholder != null;

  if (!hasItems) {
    if (!hasPlaceholder) {
      return null;
    }

    return (
      <span
        className={cn(
          "font-normal text-muted-foreground",
          /* Matching the text styles on the TextInput for consistency */
          "text-base leading-5",
          "md:text-sm"
        )}
      >
        {placeholder}
      </span>
    );
  }

  return (
    // Rendering an `ul` and `li` inside a `button` would be an invalid HTML.
    // That is why we are using a `div` here.
    <div className="flex flex-wrap gap-1">
      {selectedOptions.map((option) => {
        return (
          <Badge
            key={option.value}
            /**
             * To make sure the `button` renders at 40 px height.
             */
            className={
              "h-[1.125rem] rounded-[2px] bg-foreground pl-2 pr-1 text-background"
            }
            onClick={() => handleDeselect(option)}
            data-testid="multiselect-selected-item"
          >
            <div data-testid="multiselect-selected-item-label">
              {option.label}
            </div>
            <div
              tabIndex={isDisabled ? -1 : 0}
              className="ml-2 outline-none ring-offset-background focus:ring-2 focus:ring-foreground focus:ring-offset-2"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleDeselect(option);
                }
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                handleDeselect(option);
              }}
            >
              <span className="sr-only">Deselect {option.label}</span>
              <XIcon className="h-3 w-3 hover:text-foreground" />
            </div>
          </Badge>
        );
      })}
    </div>
  );
}

function DropdownMultiSelectOptions({
  searchable,
  className,
  options,
  handleSelect,
  value,
  menuAriaLabel,
}: {
  className?: string;
  options: { label: string; value: string }[];
  handleSelect: (option: Option) => void;
  value: string[];
  menuAriaLabel?: string;
  searchable?: boolean;
}) {
  return (
    <Command className={cn(className)}>
      {searchable ? <CommandInput placeholder="Search..." /> : null}
      <CommandEmpty>No options</CommandEmpty>
      <CommandList label={menuAriaLabel}>
        <CommandGroup className="max-h-64 overflow-auto">
          {options.map((option) => {
            return (
              <CommandItem
                keywords={[option.label]}
                key={option.value}
                onSelect={() => handleSelect(option)}
                /* Matching the text styles on the TextInput for consistency */
                className="text-base leading-5 md:text-sm"
              >
                <CheckIcon
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(option.value) ? "opacity-100" : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

DropdownMultiSelect.displayName = "DropdownMultiSelect";

type SingleSelectProps = {
  value: string;

  onChange?: (newValue: string | null) => void;
  options: { label: string; value: string }[];

  placeholder?: string;
  isErrored?: boolean;
  isRequired?: boolean;
  searchable: boolean;

  size?: "small" | "medium";
  ariaLabel?: string;

  isDisabled?: boolean;
  className?: string;

  menuAriaLabel?: string;
  id: string;
};

export const DropdownSingleSelect = forwardRef<
  HTMLButtonElement,
  SingleSelectProps
>(
  (
    {
      value,
      onChange,
      options,
      placeholder,
      searchable = true,
      isErrored,
      isDisabled,
      className,
      isRequired,
      ariaLabel,
      id,
      menuAriaLabel,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const emptyOptionValue = useId();

    const handleOnSelect = (newValue: string) => {
      const valueToPass = newValue === emptyOptionValue ? null : newValue;

      onChange?.(valueToPass);
      setOpen(false);
    };

    const hasValue = value != null && value != "" && value != emptyOptionValue;
    const renderClearSelection = hasValue && !isRequired && !isDisabled;

    return (
      <Popover open={open} onOpenChange={setOpen} modal={false}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="outline"
            role="combobox"
            disabled={isDisabled}
            aria-expanded={open}
            aria-label={ariaLabel}
            className={cn(
              "w-full",
              "bg-transparent",
              "hover:bg-transparent",
              "hover:text-inherit",
              "border border-solid border-input",
              "data-[errored=true]:border-destructive",
              "disabled:bg-transparent",
              "px-3 py-2.5",
              /* Matching the text styles on the TextInput for consistency */
              "text-base leading-5",
              "md:text-sm",
              {
                "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
                  isDisabled,
                "border-destructive": isErrored,
              },
              className
            )}
            onClick={() => setOpen(!open)}
          >
            <div className="flex items-center gap-2">
              <DropdownSingleSelectValue
                placeholder={placeholder}
                options={options}
                value={value}
              />
              <div className="ml-auto flex items-center gap-2">
                {renderClearSelection ? (
                  <ClearSelection
                    onClearSelection={() => {
                      return handleOnSelect(emptyOptionValue);
                    }}
                  />
                ) : null}
                <ChevronDownIcon
                  /**
                   * Not strictly necessary for single select, but it is there for consistency.
                   * See the comment in `DropdownMultiSelect` for the same component for more details.
                   */
                  data-testid="dropdown-trigger"
                  className="h-4 w-4 text-foreground"
                />
              </div>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full border-none p-0" align={"start"}>
          <DropdownSingleSelectOptions
            searchable={searchable}
            options={options}
            value={value}
            handleOnSelect={handleOnSelect}
            menuAriaLabel={menuAriaLabel}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

const ClearSelection = ({
  onClearSelection,
}: {
  onClearSelection: () => void;
}) => {
  return (
    <div
      tabIndex={0}
      onClick={(event) => {
        event.stopPropagation();
        onClearSelection();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          onClearSelection();
        }
      }}
    >
      <span className="sr-only">Clear selection</span>
      <XIcon
        className="h-3 w-3 rounded-full bg-muted-foreground p-0.5"
        color="hsl(var(--muted))"
      />
    </div>
  );
};

DropdownSingleSelect.displayName = "DropdownSingleSelect";

function DropdownSingleSelectValue({
  options,
  value,
  placeholder,
}: {
  options: Option[];
  value: string;
  placeholder?: string;
}) {
  const pickedValue = options.find((option) => option.value === value);
  if (pickedValue) {
    return <span className="truncate">{pickedValue.label}</span>;
  }

  if (placeholder == null) {
    return null;
  }

  return (
    <span
      className={cn(
        "font-normal text-muted-foreground",
        /* Matching the text styles on the TextInput for consistency */
        "text-base leading-5",
        "md:text-sm"
      )}
    >
      {placeholder}
    </span>
  );
}

function DropdownSingleSelectOptions({
  searchable,
  options,
  handleOnSelect,
  value,
  menuAriaLabel,
}: {
  searchable?: boolean;
  options: Option[];
  value: SingleSelectProps["value"];
  handleOnSelect: (newValue: string) => void;
  menuAriaLabel?: string;
}) {
  return (
    <Command>
      {searchable ? <CommandInput placeholder="Search..." /> : null}
      <CommandList label={menuAriaLabel}>
        <CommandEmpty>No options</CommandEmpty>
        <CommandGroup className="max-h-64 overflow-auto">
          {options.map((option) => {
            return (
              <DropdownSingleSelectOption
                key={option.value}
                value={value}
                option={option}
                onSelect={handleOnSelect}
              />
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

function DropdownSingleSelectOption({
  value,
  option,
  onSelect,
}: {
  value: string;
  option: Option;
  onSelect: (newValue: string) => void;
}) {
  const checked = option.value === value;

  return (
    <CommandItem
      value={option.value}
      keywords={[option.label]}
      onSelect={() => {
        onSelect(option.value);
      }}
      className={cn(
        /* Matching the text styles on the TextInput for consistency */
        "text-base leading-5 md:text-sm",
        /**
         * Padding is there to make sure the items are aligned properly when the `label` is short.
         * If the `label` is short, without the padding, the label would be too close to the right edge.
         */
        "min-w-10 pr-10"
      )}
    >
      <CheckIcon
        className={cn("mr-2 h-4 w-4", {
          "opacity-100": checked,
          "opacity-0": !checked,
        })}
      />
      {option.label}
    </CommandItem>
  );
}
