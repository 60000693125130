import { BlockProps } from "block-system/blocks/__shared__/types";
import { StripeCheckoutStatusModal } from "@/block-system/blocks/StripePayment/Block/StripeCheckoutStatusModal";
import { StripeEmbeddedCheckoutModal } from "@/block-system/blocks/StripePayment/Block/StripeEmbeddedCheckoutModal";
import { Button } from "block-system/brickz/components/ui/Button";
import { useRouter } from "next/router";
import { useCallback, useEffect, useId, useState } from "react";
import { trpc } from "utils/trpc";
import type { StripePaymentBlock as PaymentBlockType } from "../types";
import {
  isConfigured,
  isPaymentModeSubscription,
  useQuantityOptions,
} from "../utils";
import { ProjectStripeConnectedAccount } from "server/schemas/projects";
import {
  AccountDisabledBlockWarning,
  AccountPendingVerificationBlockWarning,
} from "block-system/blocks/StripePayment/Block/StripeAccountState";
import { IconStripe } from "block-system/components/icons/IconStripe";
import { StripeAction } from "../schema";
import { useCurrentPages } from "lib/context/current-pages-context";
import { navigateToPage } from "block-system/blocks/__shared__/lib/actions/navigation";
import { openExternalUrl } from "block-system/blocks/__shared__/lib/actions/openExternalUrl";
import { cn } from "utils/cn";
import { DropdownSelect } from "@/block-system/brickz/components/ui/DropdownSelect";
import styles from "./StripePayment.styles.module.css";
import { toast } from "@/components/Toaster";
import { Card } from "@/block-system/brickz/components/ui/card";
import { StandardBlockZeroState } from "@/block-system/components/BlockErrors/StandardBlockZeroState";
import { zrpc } from "@/lib/zrpc";

type Props = Pick<BlockProps<PaymentBlockType>, "block" | "blockId"> & {
  stripeConnectedAccount: ProjectStripeConnectedAccount;
  isPublishedPage: boolean;
};

/**
 * Use the following command
 * ngrok http --host-header="INTERFACE_PAGE_HOST" localhost:3000
 * to expose the page which holds this component via HTTPS
 */
export const StripePayment = ({
  block,
  blockId,
  stripeConnectedAccount: {
    status: connectedAccountStatus,
    id: connectedAccountId,
  },
  isPublishedPage,
}: Props) => {
  switch (connectedAccountStatus) {
    case "Enabled": {
      if (isPublishedPage && !isConfigured(block)) {
        return null;
      }

      if (!isConfigured(block)) {
        return <NotConfiguredStripePayment blockId={blockId} />;
      }

      return (
        <ConfiguredStripePayment
          connectedAccountId={connectedAccountId}
          config={block.config}
          blockId={blockId}
        />
      );
    }
    case "NeedsMoreInfo": {
      if (isPublishedPage && !isConfigured(block)) {
        return null;
      }

      if (!isConfigured(block)) {
        return <NotConfiguredStripePayment blockId={blockId} />;
      }

      return (
        <ConfiguredStripePayment
          connectedAccountId={connectedAccountId}
          config={block.config}
          blockId={blockId}
        />
      );
    }
    case "PendingVerification": {
      if (isPublishedPage) {
        return null;
      }

      if (!isConfigured(block)) {
        return <NotConfiguredStripePayment blockId={blockId} />;
      }

      return (
        <ConfiguredStripePayment
          connectedAccountId={connectedAccountId}
          config={block.config}
          blockId={blockId}
        >
          {isPublishedPage ? null : (
            <AccountPendingVerificationBlockWarning blockId={blockId} />
          )}
        </ConfiguredStripePayment>
      );
    }

    case "Disabled": {
      if (isPublishedPage && !isConfigured(block)) {
        return null;
      }

      if (!isConfigured(block)) {
        return <NotConfiguredStripePayment blockId={blockId} />;
      }

      return (
        <ConfiguredStripePayment
          connectedAccountId={connectedAccountId}
          config={block.config}
          blockId={blockId}
        >
          {isPublishedPage ? null : (
            <AccountDisabledBlockWarning blockId={blockId} />
          )}
        </ConfiguredStripePayment>
      );
    }
    default: {
      const _exhaustiveCheck: never = connectedAccountStatus;
      return _exhaustiveCheck;
    }
  }
};

export function ConfiguredStripePayment({
  config: {
    title,
    description,
    emoji,
    value,
    currency,
    quantityLimit,
    buttonLabel,
    payment,
    id: stripePaymentId,
    triggers,
  },
  blockId,
  connectedAccountId,
  children,
}: {
  config: PaymentBlockType["config"];
  blockId: string;
  connectedAccountId: string | null;
  children?: React.ReactNode;
}) {
  const {
    clientSecret,
    createCheckoutSession,
    resetCheckoutSession,
    isCreatingCheckoutSession,
  } = useCheckoutSession();

  const { quantity, quantityOptions, setQuantity } = useQuantity({
    quantityLimit,
  });

  const formattedTotal = useFormattedTotal({
    currency,
    quantity,
    value,
  });

  const renderCheckoutModal =
    clientSecret != null && connectedAccountId != null;

  const {
    status: checkoutSessionStatus,
    isLoading: isGettingCheckoutSessionStatus,
    reset: resetCheckoutSessionStatus,
  } = useCheckoutSessionStatus({
    stripePaymentId,
  });

  const isLoading = isCreatingCheckoutSession || isGettingCheckoutSessionStatus;
  const isConnectedAccount = connectedAccountId != null;
  const handlePaymentProcessed = usePaymentProcessed(
    triggers,
    stripePaymentId,
    quantity
  );

  const formattedPlaceAnOrderCTA = formatPlaceAnOrderCTA({
    payment,
    formattedTotal,
  });

  useEffect(() => {
    if (checkoutSessionStatus !== "complete") return;

    void handlePaymentProcessed();
  }, [checkoutSessionStatus, handlePaymentProcessed]);

  const quantityDropdownId = useId();

  return (
    <Card
      data-testid={`configured-stripe-payment-${blockId}`}
      className={cn("m-0 items-start gap-8 p-6")}
    >
      {children}
      <div className={"flex w-full max-w-full flex-col items-start gap-2"}>
        {emoji ? <Emoji emoji={emoji} /> : null}
        <div className="flex w-full max-w-full flex-col items-start gap-1">
          <span
            className={cn(
              "w-full overflow-x-auto leading-10 text-h6 [&&]:text-card-foreground"
            )}
          >
            {title}
          </span>
          {description ? <Description description={description} /> : null}
        </div>
      </div>

      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault();

          if (!isConnectedAccount) {
            return;
          }

          if (!stripePaymentId) {
            return;
          }

          createCheckoutSession({
            params: {
              path: {
                block_id: stripePaymentId,
              },
            },
            body: {
              quantity,
            },
          });
        }}
      >
        <fieldset disabled={!isConnectedAccount}>
          <legend>
            <span className={"sr-only"}>{title}</span>
          </legend>
          <label htmlFor={quantityDropdownId} className="sr-only">
            Quantity
          </label>
          <div className={styles["form-grid"]}>
            <DropdownSelect
              id={quantityDropdownId}
              value={`${quantity}`}
              options={quantityOptions}
              isRequired={true}
              onChange={(newQuantity) => setQuantity(Number(newQuantity))}
              className="h-full"
            />
            <PlaceAnOrderButton
              isLoading={isLoading}
              buttonLabel={buttonLabel}
              formattedTotal={formattedPlaceAnOrderCTA}
            />
          </div>
        </fieldset>
      </form>
      {renderCheckoutModal ? (
        <StripeEmbeddedCheckoutModal
          onComplete={handlePaymentProcessed}
          onClosed={() => resetCheckoutSession()}
          connectedAccountId={connectedAccountId}
          clientSecret={clientSecret}
        />
      ) : null}
      {checkoutSessionStatus != null ? (
        <StripeCheckoutStatusModal
          checkoutStatus={checkoutSessionStatus}
          onClosed={() => {
            resetCheckoutSessionStatus();
          }}
        />
      ) : null}
    </Card>
  );
}

function Description({ description }: { description: string }) {
  return (
    <span
      className={cn(
        "w-[100%] overflow-x-auto text-base font-normal [&&]:text-card-foreground-subtle"
      )}
    >
      {description}
    </span>
  );
}

function Emoji({ emoji }: { emoji: string }) {
  return <div className="text-[40px]">{emoji}</div>;
}

function PlaceAnOrderButton({
  isLoading,
  formattedTotal,
  buttonLabel,
}: {
  isLoading: boolean;
  buttonLabel: string;
  formattedTotal: string;
}) {
  return (
    <Button
      /**
       * The button does not render any text when `isLoading` is true.
       *
       * To allow making assertions on the state of the button in tests,
       * we have added the `data-loading` attribute.
       */
      data-loading={isLoading}
      color="primary"
      size="large"
      type="submit"
      isLoading={isLoading}
      loadingText="Placing order"
      className={"overflow-hidden"}
    >
      {/* These styles ensure that, if the `buttonLabel` is long, it will be truncated,
      but the price will always be visible. */}
      <div className="flex items-center justify-center gap-1">
        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
          {buttonLabel}
        </span>
        <span>-</span>
        <span>{formattedTotal}</span>
      </div>
    </Button>
  );
}

function useCheckoutSessionStatus({
  stripePaymentId,
}: {
  stripePaymentId: string | undefined;
}) {
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(
    null
  );

  const isQueryEnabled = checkoutSessionId != null && stripePaymentId != null;
  const { data, isInitialLoading, isError } =
    trpc.payments.getCheckoutSessionStatus.useQuery(
      {
        sessionId: checkoutSessionId as string,
        stripePaymentId: stripePaymentId as string,
      },
      /**
       * staleTime: Infinity -> We do not want to refetch this function.
       * This function is only relevant when Stripe redirects to a page which contains this block.
       */
      {
        enabled: isQueryEnabled,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        throwOnError: false,
        meta: {
          /**
           * We are handling the error in the `useEffect` below.
           */
          noToast: true,
        },
      }
    );

  /**
   * See https://tanstack.com/query/v5/docs/framework/react/guides/migrating-to-v5#callbacks-on-usequery-and-queryobserver-have-been-removed
   */
  useEffect(() => {
    if (!isError) {
      return;
    }

    toast.error({ message: "Failed to retrieve payment status." });
  }, [isError]);

  const { isReady, asPath, replace, query } = useRouter();
  useEffect(() => {
    if (!isReady) {
      return;
    }

    if (!stripePaymentId) {
      return;
    }

    const { stripePaymentId: stripePaymentIdFromQuery, checkoutSessionId } =
      query;

    if (stripePaymentIdFromQuery !== stripePaymentId) {
      return;
    }

    if (!checkoutSessionId || Array.isArray(checkoutSessionId)) {
      return;
    }

    setCheckoutSessionId(checkoutSessionId);

    const currentUrl = new URL(asPath, window.location.origin);
    currentUrl.searchParams.delete("stripePaymentId");
    currentUrl.searchParams.delete("checkoutSessionId");

    void replace(`${currentUrl.pathname}${currentUrl.search}`, undefined, {
      shallow: true,
    });
  }, [asPath, isReady, query, replace, stripePaymentId]);

  return {
    status: data?.status,
    isLoading: isInitialLoading,
    reset: () => {
      setCheckoutSessionId(null);
    },
  };
}

export function NotConfiguredStripePayment({ blockId }: { blockId: string }) {
  return (
    <section data-testid={`not-configured-stripe-payment-${blockId}`}>
      <StandardBlockZeroState
        icon={<IconStripe size={40} />}
        title="Configure product in sidebar"
        className="w-full"
      />
    </section>
  );
}

function useCheckoutSession() {
  const {
    mutate: createCheckoutSession,
    isPending: isCreatingCheckoutSession,
    data = { clientSecret: null },
    reset,
  } = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/blocks/stripe/{block_id}/checkout-session"
  );
  return {
    createCheckoutSession,
    resetCheckoutSession: reset,
    clientSecret: data.clientSecret,
    isCreatingCheckoutSession,
  };
}

function useQuantity({ quantityLimit }: { quantityLimit: number }) {
  const [quantity, setQuantity] = useState(1);
  const quantityOptions = useQuantityOptions(quantityLimit);

  return { quantity, setQuantity, quantityOptions };
}

function useFormattedTotal({
  quantity,
  value,
  currency,
}: {
  quantity: number;
  value: number;
  currency: string;
}) {
  const total = (value * quantity) / 100;

  const formattedTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(total);

  return formattedTotal;
}

function formatPlaceAnOrderCTA({
  formattedTotal,
  payment,
}: {
  formattedTotal: string;
  payment: PaymentBlockType["config"]["payment"];
}) {
  const labels = {
    week: "week",
    month: "month",
    year: "year",
  };

  if (isPaymentModeSubscription(payment)) {
    return `${formattedTotal} / ${labels[payment.interval]}`;
  }

  return formattedTotal;
}

function usePaymentProcessed(
  triggers: StripeAction[],
  stripePaymentId: string | undefined,
  quantity: number
) {
  const pages = useCurrentPages();
  const router = useRouter();
  const { mutateAsync: registerPaymentProcessed } =
    trpc.payments.triggerPaymentProcessed.useMutation();

  const handleStripeAction = useCallback(
    async (action: StripeAction) => {
      const type = action.type;

      switch (type) {
        case "navigate":
          return await navigateToPage(action, pages, router);
        case "openExternalUrl":
          return openExternalUrl(action);
        case "notification":
          return toast.success(
            {
              message: action.config.message,
            },
            {
              position: action.config.position ?? "bottom-center",
            }
          );
        default:
          const _exhaustiveCheck: never = type;
          return _exhaustiveCheck;
      }
    },
    [pages, router]
  );

  return useCallback(async () => {
    if (!stripePaymentId) return;

    triggers.forEach(handleStripeAction);

    await registerPaymentProcessed({
      blockId: stripePaymentId,
      date: new Date().toISOString(),
      quantity,
    });
  }, [
    triggers,
    stripePaymentId,
    quantity,
    handleStripeAction,
    registerPaymentProcessed,
  ]);
}
