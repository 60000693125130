import { cn } from "utils/cn";
import { FileWarningIcon } from "lucide-react";

export function StandardBlockErrorState({
  title = "Something is amiss",
  description = "This component is not configured appropriately.",
  className,
}: {
  title?: string;
  description?: string;
  className?: string;
}) {
  return (
    <section
      className="flex h-full w-full items-center justify-center"
      data-testid={`block-error-state`}
    >
      <div
        className={cn(
          "bg-muted",
          "h-[200px] w-[520px] min-w-[300px]",
          "flex items-center justify-center",
          "mx-auto",
          "rounded",
          "flex",
          "flex-col",
          "gap-4",
          className
        )}
      >
        <div
          className={cn("bg-card text-card-foreground", "p-2", "rounded-sm")}
        >
          <FileWarningIcon className="h-6 w-6" />
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-center text-sm font-medium leading-[0.875rem] text-muted-foreground">
            {title}
          </h2>

          <p className="text-center text-sm font-normal leading-tight text-muted-foreground">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
}
