import partition from "lodash/partition";
import { APIFieldType } from "server/services/tables/types/fields";
import { APIFieldLatest } from "server/services/tables/types/tables-latest";

const SUPPORTED_TABLES_FIELD_TYPES: APIFieldType[] = [
  "string",
  "multiple_string",
  "labeled_string",
  "multiple_labeled_string",
  "text",
  "boolean",
  "number",
  "decimal",
  "email",
  "link",
  "datetime",
  "currency",
  "phone_number",
  "multiple_link",
  "linked_record",
  "multiple_linked_record",
  "ai_formula",
  "formula",
];

export function filterUnsupportedKanbanFields(
  fields?: APIFieldLatest[]
): Array<Array<APIFieldLatest> | undefined> {
  if (!fields) return [undefined, undefined];

  return partition(fields, (field) =>
    SUPPORTED_TABLES_FIELD_TYPES.includes(field.type)
  );
}
