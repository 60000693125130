import BlockWrapper from "block-system/components/BlockWrapper";
import {
  BlockId,
  blockIsFocused,
  FormAutomationFocusStateData,
  NavigateAction,
  NotificationAction,
  OpenExternalUrlAction,
} from "block-system/types";
import { useCurrentPages } from "lib/context/current-pages-context";
import { usePageContext } from "lib/context/page-context";
import { useRenderRecaptcha } from "lib/hooks/RecaptchaProvider";
import { useContentDatasource } from "lib/hooks/useContentDatasource";
import { checkFeature } from "lib/hooks/useFeatureAccessCheck";
import { useTheme } from "lib/theme";
import omit from "lodash/omit";
import { useRouter } from "next/router";
import { useTrackingContext } from "observability/tracking";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { PaidFeature } from "server/auth/types";
import { trpc } from "utils/trpc";
import {
  isConditionallyRendered,
  shouldConditionallyRender,
} from "../../Field/lib/conditional-logic/conditional-logic";
import { navigateToPage } from "../lib/actions/navigation";
import { openExternalUrl } from "../lib/actions/openExternalUrl";
import { parseUrlParamValue } from "../lib/parseUrlParamValue";
import {
  getDefaultValueForFieldBlock,
  parseDynamicDefaultValue,
} from "../lib/blockValueHelpers";
import { getFormDataAndQueryParams } from "../lib/getFormDataAndQueryParams";
import { FormBlock as FormBlockType } from "../schema";
import { Form } from "./Form";
import { TablePermissionErrorBoundary } from "./TablePermissionErrorBoundary";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import { toast } from "@/components/Toaster";
import { zrpc } from "@/lib/zrpc";

export type FormData = Record<string, any>;

const AUTOMATION_PANELS: FormAutomationFocusStateData["panel"][] = [
  "automation",
  "automation-new",
  "automation-edit-zap",
  "automation-edit-blockAction",
];

export function FormBlock(props: { block: FormBlockType; blockId: BlockId }) {
  const { config } = props.block;
  const theme = useTheme();

  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [recaptchaKey, setRecaptchaKey] = useState<number>();
  const { data: datasource } = useContentDatasource();
  const { emitConsumerInteractionEvent } = useTrackingContext();
  const recaptcha = useRenderRecaptcha();

  const { pageId, projectId, isEditing, isThumbnail, isEmbedded, focusState } =
    usePageContext();

  const actionIsFocused: boolean =
    blockIsFocused(focusState) &&
    focusState.blockId === props.blockId &&
    AUTOMATION_PANELS.some((panel) => panel === focusState.data?.panel);

  const project = useCurrentConsumerProject();
  const pages = useCurrentPages();

  const projectCreator = {
    ...project.creator,
    paidFeatureAccess: project.paidFeatureAccess,
  };

  const router = useRouter();

  const defaultValues = props.block.children.reduce((prev, item) => {
    const { name } = item.config;
    let defaultValue = getDefaultValueForFieldBlock(item);
    defaultValue = parseDynamicDefaultValue(item, defaultValue, datasource);

    const value = parseUrlParamValue(item.config, router.query[name]);
    return {
      ...prev,
      [name]: value ?? defaultValue,
    };
  }, {});

  const methods = useForm<FormData>({ mode: "onBlur", defaultValues });

  methods.register("captcha");

  const { isSubmitting, isSubmitSuccessful } = methods.formState;

  useEffect(() => {
    if (isSubmitSuccessful) {
      methods.reset();
    }
  }, [methods, isSubmitSuccessful]);

  const hasNavigationAction = config.triggers?.some(
    (action) => action.type === "navigate"
  );

  const handleOnSubmitSuccessful = async (
    queryParamsData: Record<string, string>
  ) => {
    setRecaptchaToken(undefined);
    if (config.captchaEnabled) {
      void recaptcha.reset(recaptchaKey);
    }

    void utils.tableFields.getChoices.invalidate();
    if (config.storageId && config.storageType === "tables") {
      void utils.blockTable.queryRecords.invalidate();
      void utils.blockKanban.queryRecords.invalidate();
      void utils.blockKanban.get.invalidate();
    }

    emitConsumerInteractionEvent({
      interaction_goal: "submit form",
      event_action: "click",
      interfaces_project_id: projectId,
      interfaces_page_id: pageId,
      interfaces_component_type: "form",
      embedded_flag: isEmbedded,
    });

    const notificationAction = config.triggers?.find(
      (action): action is NotificationAction => action.type === "notification"
    );

    const navigateAction = config.triggers?.find(
      (action): action is NavigateAction => action.type === "navigate"
    );

    const externalUrlAction = config.triggers?.find(
      (action): action is OpenExternalUrlAction =>
        action.type === "openExternalUrl"
    );

    if (notificationAction) {
      toast.success({ message: notificationAction.config.message });
    }

    if (navigateAction && !isEditing) {
      if (!project) throw new Error("Missing project");
      await navigateToPage(navigateAction, pages, queryParamsData);
    }

    if (externalUrlAction && !isEditing) {
      openExternalUrl(externalUrlAction, queryParamsData);
    }
  };

  const utils = trpc.useUtils();
  const createFormSubmission = zrpc.interfaces.useMutation(
    "post",
    "/api/interfaces/v0/blocks/form/{block_id}/submissions"
  );

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (config.captchaEnabled && !recaptchaToken) {
      methods.setError("captcha", {
        type: "custom",
        message: "Please check the CAPTCHA box.",
      });
      return;
    }

    const [formData, queryParamsData] = getFormDataAndQueryParams(
      props.block,
      omit(data, "captcha")
    );
    if (!config.id) {
      return;
    }

    await createFormSubmission
      .mutateAsync({
        params: {
          path: {
            block_id: config.id,
          },
        },
        body: {
          values: formData,
          captcha: recaptchaToken ?? undefined,
        },
      })
      .then(() => {
        return handleOnSubmitSuccessful(queryParamsData);
      })
      /**
       * See https://github.com/orgs/react-hook-form/discussions/9879
       */
      .catch(() => {});
  };

  const values = methods.watch();

  const fieldBlocksToRender = useMemo(() => {
    return props.block.children.filter((fieldBlock) => {
      if (
        !isEditing &&
        isConditionallyRendered(fieldBlock) &&
        !shouldConditionallyRender(fieldBlock, props.block.children, values)
      ) {
        // Reset field if it has been modified.
        if (methods.formState.dirtyFields[fieldBlock.config.name]) {
          methods.resetField(fieldBlock.config.name);
        }
        return false;
      }
      return true;
    });
  }, [props.block.children, isEditing, values, methods]);

  const isPaidUser = useIsPaidUser(projectCreator);

  const isDisabled =
    isSubmitting ||
    (isSubmitSuccessful && hasNavigationAction) ||
    isThumbnail === true;

  return (
    <BlockWrapper
      blockId={props.blockId}
      block={props.block}
      isFocused={actionIsFocused ? false : undefined}
      maxWidth={
        config.style?.width
          ? theme.app.width[config.style?.width]
          : theme.app.width.narrow
      }
      alignment={config.style?.alignment}
    >
      <TablePermissionErrorBoundary
        tableId={config.storageId}
        isEditing={isEditing}
      >
        <FormProvider {...methods}>
          <Form
            onSubmit={onSubmit}
            isDisabled={isDisabled}
            config={config}
            fieldBlocksToRender={fieldBlocksToRender}
            isEditing={isEditing}
            isPaidUser={isPaidUser}
            blockId={props.blockId}
            actionIsFocused={actionIsFocused}
            setRecaptchaToken={setRecaptchaToken}
            setRecaptchaKey={setRecaptchaKey}
          />
        </FormProvider>
      </TablePermissionErrorBoundary>
    </BlockWrapper>
  );
}

function useIsPaidUser(user: {
  isStaff?: boolean;
  paidFeatureAccess?: PaidFeature[];
}) {
  // We check if the user has access to the passwordProtect feature
  // to determine if they are a paid user. We can't check the account plan
  // since we want this to work on published pages
  return checkFeature("passwordProtect", user);
}
