import { cn } from "@/block-system/brickz/lib/utils";

type props = {
  value: string;
  className?: string;
};

export const FormulaInput = ({ value, className }: props) => {
  return (
    <div
      className={cn(
        "border-1 min-h-10 rounded-medium border border-input text-sm text-muted-foreground",
        "px-3 py-2.5",
        className
      )}
    >
      {value}
    </div>
  );
};
