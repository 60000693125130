import type { UploadStatusNew } from "@/server/services/uploads/status";
import { UPLOAD_SIGNATURE_LIFETIME_MS } from "./constants";
import { getNextRefetch, PollingConfig } from "./getNextRefetch";
import { zrpc } from "@/lib/zrpc";
import type { UseQueryResult } from "@tanstack/react-query";

// Upload Signature Query
// -----------------------------------------------------------------------------
export type BuilderUploadSignatureQueryArgs = {
  entityType: "block" | "project";
  entityId: string;
  interfaceId: string;
};

export function useBuilderUploadSignatureQuery(
  args: BuilderUploadSignatureQueryArgs,
  // NOTE: I wanted to allow all the options from `useQuery` to be passed in.
  // But for some reason that makes the return value `unknown`.
  options?: { enabled?: boolean }
) {
  return zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/uploads/signature",
    {
      params: {
        path: {
          interfaceId: args.interfaceId,
        },
        query: {
          entity_type: args.entityType,
          entity_id: args.entityId,
        },
      },
    },
    {
      staleTime: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
      refetchInterval: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      throwOnError: false,
      ...options,
    }
  );
}

export type PublishedPageUploadSignatureQueryArgs = {
  blockId: string;
};
export function usePublishedUploadSignatureQuery(
  args: PublishedPageUploadSignatureQueryArgs,
  // NOTE: I wanted to allow all the options from `useQuery` to be passed in.
  // But for some reason that makes the return value `unknown`.
  options?: { enabled?: boolean }
) {
  return zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/blocks/field/{fieldId}/uploads/signature",
    {
      params: {
        path: {
          fieldId: args.blockId,
        },
      },
    },
    {
      staleTime: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
      refetchInterval: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      throwOnError: false,
      ...options,
    }
  );
}

// Upload Status Query
// -----------------------------------------------------------------------------
export type BuilderUploadGetStatusQueryArgs = {
  entityType: "block" | "project";
  entityId: string;
  interfaceId: string;
  uuids: string[];
};

export function useBuilderPollingUploadStatusQuery(
  { entityType, entityId, interfaceId, uuids }: BuilderUploadGetStatusQueryArgs,
  config?: PollingConfig
) {
  const query = zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/uploads/status",
    {
      params: {
        path: {
          interfaceId: interfaceId,
        },
        query: {
          entity_type: entityType,
          entity_id: entityId,
          uuids,
        },
      },
    },
    {
      enabled: uuids.length > 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      throwOnError: false,
      refetchInterval: (query) => {
        const hasPendingUploads = query.state?.data?.some(
          (status) => status?.status === "pending"
        );
        if (!hasPendingUploads) {
          return false;
        }

        const allDataFetchesAttempted =
          query.state.dataUpdateCount +
          query.state.errorUpdateCount +
          query.state.fetchFailureCount;

        return getNextRefetch(allDataFetchesAttempted, config) ?? false;
      },
    }
  );

  return query;
}

export type PublishedPageUploadGetStatusQueryArgs = {
  blockId: string;
  uuids: string[];
};

export function usePublishedPagePollingUploadStatusQuery(
  { blockId, uuids }: PublishedPageUploadGetStatusQueryArgs,
  config?: PollingConfig
) {
  const query = zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/blocks/field/{fieldId}/uploads/status",
    {
      params: {
        path: {
          fieldId: blockId,
        },
        query: {
          uuids,
        },
      },
    },
    {
      enabled: uuids.length > 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      throwOnError: false,
      refetchInterval: (query) => {
        const hasPendingUploads = query.state?.data?.some(
          (status) => status?.status === "pending"
        );
        if (!hasPendingUploads) {
          return false;
        }

        const allDataFetchesAttempted =
          query.state.dataUpdateCount +
          query.state.errorUpdateCount +
          query.state.fetchFailureCount;

        return getNextRefetch(allDataFetchesAttempted, config) ?? false;
      },
    }
  );

  return query satisfies UseQueryResult<UploadStatusNew[], unknown>;
}

/**
 * While upgrading to PNPM 9.x we were getting a very weird error here related to ESLint and TypeScript.
 * https://gitlab.com/zapier/team-fpv/interfaces/-/jobs/9469593240
 *
 * This is a workaround for that error.
 * We have also added additional constraint on the return value of the query to ensure that this type is aligned with the actual return value.
 */
export type UploadStatusData = UploadStatusNew;
