import type { Button as ButtonType } from "./types";
import { strings } from "./strings";
import {
  blockNameToType,
  blockType,
  blockTypeDbName,
  blockTypeToName,
} from "./constants";
import { create, copy, map } from "./operations";

export const ButtonBlockDefinition: ButtonType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
  create,
  map,
  copy,
  isStandalone: true,
} as const;
