import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import { zrpc } from "@/lib/zrpc";
import { ProjectAuthType } from "@prisma/client";
import { SSR_STALE_TIME } from "lib/constants";
import { usePageContext } from "lib/context/page-context";

export function useConsumer() {
  const { isEditing, isThumbnail, pageId } = usePageContext();
  const project = useCurrentConsumerProject();
  const isLoginPage = pageId === project.loginPageId;

  const isValidAuthType =
    project.authType === ProjectAuthType.Consumer ||
    project.authType === ProjectAuthType.Stytch;

  const { data: consumer } = zrpc.interfaces.useQuery(
    "get",
    "/api/interfaces/v0/interfaces/{interfaceId}/consumers/me",
    { params: { path: { interfaceId: project.id } } },
    {
      staleTime: SSR_STALE_TIME,
      enabled: isValidAuthType && !isEditing && !isThumbnail && !isLoginPage,
    }
  );

  return consumer;
}
