import { blockType } from "./constants";
import { ButtonBlockSchema } from "./schema";
import { Button, ButtonBlock } from "./types";

export function copy(block: ButtonBlock): ButtonBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Button["create"] = ({ initialConfig = {} } = {}) => {
  const initialData: ButtonBlock = {
    type: blockType,
    config: {
      type: "primary",
      label: "Button",
      size: "medium",
      width: "fitContent",
      colorType: "theme",
      alignment: "center",
      actions: [],
      style: {
        width: "narrow",
        alignment: "center",
      },
      ...initialConfig,
    },
  };

  return ButtonBlockSchema.parse(initialData);
};

export const map: Button["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...ButtonBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
