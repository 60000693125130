import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import { ReactNode } from "react";
import { cn } from "utils/cn";
import { Button } from "@/block-system/brickz/components/ui/Button";
import { Plus } from "lucide-react";
import { strings } from "../../strings";

import {
  Card,
  CardContent,
  CardTitle,
} from "@/block-system/brickz/components/ui/card";

export function Kanban(props: {
  disableScroll?: boolean;
  onDragEnd: OnDragEndResponder;
  children: ReactNode;
}) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <div
        className={cn(
          "flex w-full flex-row flex-nowrap items-start gap-x-3 overflow-x-auto",
          {
            "overflow-x-hidden": !!props.disableScroll,
          }
        )}
      >
        {props.children}
      </div>
    </DragDropContext>
  );
}

Kanban.Column = function Column(props: {
  droppableId: string;
  title?: ReactNode;
  isDisabled?: boolean;
  isDropDisabled?: boolean;
  disableScroll?: boolean;
  onClickCreate?: () => void;
  newCardButtonCopy?: string;
  newCardButtonAlwaysVisible?: boolean;
  children?: ReactNode;
}) {
  return (
    <Droppable
      droppableId={props.droppableId}
      isDropDisabled={props.isDropDisabled}
    >
      {(droppableProvided, droppableSnapshot) => {
        return (
          <div
            ref={droppableProvided.innerRef}
            className={cn(
              "flex flex-shrink-0 flex-grow-0 basis-auto flex-col flex-nowrap gap-2",
              "max-h-[682px] w-72",
              "rounded-large",
              "p-3",
              "bg-muted",
              {
                "bg-muted/50": droppableSnapshot.isDraggingOver,
              },
              "group"
            )}
            {...droppableProvided.droppableProps}
          >
            {props.title ? (
              <span
                className={cn(
                  "text-base font-semibold leading-relaxed text-muted-foreground"
                )}
              >
                {props.title}
              </span>
            ) : null}

            <div
              className={cn([
                "flex flex-1 flex-col gap-2",
                {
                  "overflow-hidden": !!props.disableScroll,
                  "overflow-y-auto": !props.disableScroll,
                },
              ])}
            >
              {props.children}
              {droppableProvided.placeholder}
            </div>

            {props.onClickCreate !== undefined ? (
              <div
                className={cn({
                  "pointer-events-none opacity-0":
                    !props.newCardButtonAlwaysVisible,
                  "transition-opacity duration-100":
                    !props.newCardButtonAlwaysVisible,
                  "group-hover:pointer-events-auto group-hover:opacity-100":
                    !props.newCardButtonAlwaysVisible,
                })}
              >
                <AddCardButton
                  newCardButtonCopy={props.newCardButtonCopy}
                  isDisabled={props.isDisabled}
                  onClickCreate={props.onClickCreate}
                />
              </div>
            ) : null}
          </div>
        );
      }}
    </Droppable>
  );
};

Kanban.Card = function KanbanCard(props: {
  draggableId: string;
  index: number;
  isDragDisabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <Draggable
      draggableId={props.draggableId}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
    >
      {(draggableProvided, draggableSnapshot) => {
        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <Card
              onClick={props.onClick}
              className={cn("min-h-fit gap-0.5 border-none p-4 shadow-sm", {
                "rotate-[4deg] shadow-md": draggableSnapshot.isDragging,
                "cursor-pointer": !!props.onClick,
              })}
            >
              {props.children}
            </Card>
          </div>
        );
      }}
    </Draggable>
  );
};

Kanban.CardTitle = function KanbanCardTitle(props: { children: ReactNode }) {
  return <CardTitle>{props.children}</CardTitle>;
};

Kanban.CardBody = function KanbanCardBody(props: { children: ReactNode }) {
  return (
    <CardContent className="min-h-fit break-words p-0 text-sm text-card-foreground-subtle">
      {props.children}
    </CardContent>
  );
};

function AddCardButton({
  newCardButtonCopy,
  isDisabled,
  onClickCreate,
}: {
  newCardButtonCopy?: string;
  isDisabled?: boolean;
  onClickCreate: VoidFunction;
}) {
  return (
    <Button
      variant="ghost"
      disabled={isDisabled}
      onClick={onClickCreate}
      className="w-full"
    >
      <div className="flex w-full items-center justify-center gap-2.5">
        <Plus size={16} />
        <span>{newCardButtonCopy ?? strings.NEW_CARD_BUTTON_DEFAULT_COPY}</span>
      </div>
    </Button>
  );
}
