import { LabeledRadio, Field, TextInput, Link } from "@zapier/design-system";
import type {
  FieldBlockFileUpload,
  FieldBlockMultipleFileUpload,
} from "../../types";
import { FieldGroup } from "components/BlockEditor/components";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { FILE_EXTENSIONS_WHITELIST } from "server/services/uploads/FILE_UPLOAD_WHITELIST";
import {
  ADVANCED_FILE_SIZE,
  PAID_USERS_MAX_ALLOWED_FILE_COUNT,
  FREE_USER_MAX_ALLOWED_FILE_COUNT,
  FREE_FILE_SIZE,
  PREMIUM_FILE_SIZE,
} from "../../Block";
import { useUser } from "lib/context/user-context";
import { useAccountPlan } from "lib/hooks/useAccountPlan";
import {
  getInterfacesAdvancedBillingUrl,
  getInterfacesPremiumBillingUrl,
} from "lib/route-helpers";

type Props = {
  config: FieldBlockFileUpload | FieldBlockMultipleFileUpload;
  handleChange: <T>(newConfig: Partial<T>) => void;
  handleInputChange: <T>(
    name: keyof T
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  errors: Set<string>;
  setErrors: Dispatch<SetStateAction<Set<string>>>;
};

export const allowedFileTypesConfigToArray = (fileTypes: string) => {
  return fileTypes
    .split(",")
    .map((ext) => ext.toLowerCase().split(".").pop()?.trim() ?? "")
    .filter((ext) => ext !== "");
};

const getUnsupportedFileErrorMessage = (fileTypes?: string) => {
  if (!fileTypes) return;

  const unsupportedTypes: string[] = [];
  const userAllowedFileTypes = allowedFileTypesConfigToArray(fileTypes);
  userAllowedFileTypes.forEach(
    (ext) =>
      !FILE_EXTENSIONS_WHITELIST.includes(ext) && unsupportedTypes.push(ext)
  );

  return `Unsupported file types: ${unsupportedTypes.join(",")}`;
};

const MaxFileSizeField = ({
  config,
  handleChange,
  user,
  isPremium,
  isAdvanced,
  isFree,
}: {
  config: FieldBlockFileUpload | FieldBlockMultipleFileUpload;
  handleChange: <T>(newConfig: Partial<T>) => void;
  user: any;
  isPremium: () => boolean;
  isAdvanced: () => boolean;
  isFree: () => boolean;
}) => {
  const renderHelpText = () => {
    if (isFree())
      return (
        <>
          <span>Your plan allows up to 5MB file uploads. </span>
          <Link href={getInterfacesPremiumBillingUrl(window.location.href)}>
            Upgrade
          </Link>
        </>
      );
    if (isPremium())
      return (
        <>
          <span>Your plan allows up to 10MB file uploads. </span>
          <Link href={getInterfacesAdvancedBillingUrl(window.location.href)}>
            Upgrade
          </Link>
        </>
      );

    return null;
  };

  return (
    <Field
      label="Max File Size"
      renderHelpText={renderHelpText}
      renderInput={() => (
        <>
          <LabeledRadio
            checked={
              config.allowedFileSize === FREE_FILE_SIZE ||
              !config.allowedFileSize
            }
            name="radio"
            onChange={() =>
              handleChange({
                allowedFileSize: FREE_FILE_SIZE,
              })
            }
            tabIndex={1}
          >
            5MB
          </LabeledRadio>
          <LabeledRadio
            disabled={!isPremium() && !isAdvanced() && !user?.isStaff}
            checked={config.allowedFileSize === PREMIUM_FILE_SIZE}
            name="radio"
            onChange={() =>
              handleChange({ allowedFileSize: PREMIUM_FILE_SIZE })
            }
            tabIndex={2}
          >
            {isPremium() || isAdvanced() || user?.isStaff
              ? "10MB"
              : "10MB - Pro Plan Required"}
          </LabeledRadio>
          <LabeledRadio
            disabled={!isAdvanced() && !user?.isStaff}
            checked={config.allowedFileSize === ADVANCED_FILE_SIZE}
            name="radio"
            onChange={() =>
              handleChange({ allowedFileSize: ADVANCED_FILE_SIZE })
            }
            tabIndex={3}
          >
            {isAdvanced() || user?.isStaff
              ? "25MB"
              : "25MB - Advanced Plan Required"}
          </LabeledRadio>
        </>
      )}
    />
  );
};

const FileLimitField = ({
  config,
  handleChange,
  errors,
  validateFileCount,
  isFree,
}: {
  config: FieldBlockMultipleFileUpload;
  handleChange: <T>(newConfig: Partial<T>) => void;
  errors: Set<string>;
  validateFileCount: ({
    value,
    limit,
  }: {
    value: string;
    limit: number;
  }) => void;
  isFree: () => boolean;
}) => {
  const maxFileCountAllowedByPlan = isFree()
    ? FREE_USER_MAX_ALLOWED_FILE_COUNT
    : PAID_USERS_MAX_ALLOWED_FILE_COUNT;

  const renderMultipleFileUploadHelpText = () => {
    if (isFree())
      return (
        <div className="flex flex-col gap-2">
          <div>
            <span>
              Your plan allows up to a maximum of{" "}
              {FREE_USER_MAX_ALLOWED_FILE_COUNT} files.{" "}
            </span>
            <Link href={getInterfacesPremiumBillingUrl(window.location.href)}>
              Upgrade
            </Link>
          </div>
        </div>
      );
    return <span>Type the maximum number of files users can upload.</span>;
  };

  return (
    <Field
      error={
        errors.has("allowedFileCount")
          ? `Enter a number between 1 and ${maxFileCountAllowedByPlan}`
          : undefined
      }
      label={`Set files limit`}
      renderInput={(inputProps) => (
        <TextInput
          {...inputProps}
          inputMode="numeric"
          type="number"
          onChange={(e) =>
            handleChange({
              allowedFileCount: e.target.value
                ? parseInt(e.target.value)
                : undefined,
            })
          }
          value={config?.allowedFileCount?.toString() ?? ""}
          onBlur={(e) =>
            validateFileCount({
              value: e.target.value,
              limit: maxFileCountAllowedByPlan,
            })
          }
        />
      )}
      renderHelpText={renderMultipleFileUploadHelpText}
    />
  );
};

const AllowedFileTypesField = ({
  config,
  handleInputChange,
  errors,
  validateFileTypes,
}: {
  config: FieldBlockFileUpload | FieldBlockMultipleFileUpload;
  handleInputChange: <T>(
    name: keyof T
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  errors: Set<string>;
  validateFileTypes: () => void;
}) => {
  return (
    <Field
      error={
        errors.has("allowedFileTypes")
          ? getUnsupportedFileErrorMessage(config.allowedFileTypes)
          : undefined
      }
      label="Allowed File Types"
      renderInput={(inputProps) => (
        <TextInput
          {...inputProps}
          onChange={handleInputChange("allowedFileTypes")}
          value={config.allowedFileTypes ?? ""}
          onBlur={() => validateFileTypes()}
        />
      )}
      renderHelpText={() => (
        <span>
          Separate with commas. Click{" "}
          <Link
            target="_blank"
            href="https://help.zapier.com/hc/en-us/articles/31254058977293-File-types-supported-in-Interfaces-Form-components"
          >
            here
          </Link>{" "}
          for full list of supported file types.
        </span>
      )}
    />
  );
};

export function FileUploadFields(props: Props) {
  const { user } = useUser();
  const { isAdvanced, isPremium, isFree } = useAccountPlan(user);

  const validateFileTypes = () => {
    if (!props.config.allowedFileTypes) return;
    const userAllowedFileTypes = allowedFileTypesConfigToArray(
      props.config.allowedFileTypes
    );

    if (
      userAllowedFileTypes.some(
        (ext) => !FILE_EXTENSIONS_WHITELIST.includes(ext)
      )
    ) {
      props.setErrors(new Set(props.errors).add("allowedFileTypes"));
    } else {
      const errors = new Set(props.errors);
      errors.delete("allowedFileTypes");
      props.setErrors(errors);
    }
  };

  const validateFileCount = ({
    value,
    limit,
  }: {
    value: string;
    limit: number;
  }) => {
    const fileCount = parseInt(value);
    // We only validate the max does not exceed the limit.
    // The file count field is nullable in which case we default to maximum allowed by the plan.
    // Therefore we do not raise an error if the field is empty.
    if (
      (value !== "" && isNaN(fileCount)) ||
      fileCount > limit ||
      fileCount < 1
    ) {
      props.setErrors(new Set(props.errors).add("allowedFileCount"));
    } else {
      const errors = new Set(props.errors);
      errors.delete("allowedFileCount");
      props.setErrors(errors);
    }
  };

  return (
    <>
      <FieldGroup>
        <MaxFileSizeField
          config={props.config}
          handleChange={props.handleChange}
          user={user}
          isPremium={isPremium}
          isAdvanced={isAdvanced}
          isFree={isFree}
        />
      </FieldGroup>
      {props.config.inputType === "multiple-file-upload" && (
        <FieldGroup>
          <FileLimitField
            config={props.config}
            handleChange={props.handleChange}
            errors={props.errors}
            validateFileCount={validateFileCount}
            isFree={isFree}
          />
        </FieldGroup>
      )}
      <FieldGroup>
        <AllowedFileTypesField
          config={props.config}
          handleInputChange={props.handleInputChange}
          errors={props.errors}
          validateFileTypes={validateFileTypes}
        />
      </FieldGroup>
    </>
  );
}
