import { FieldBlockConfig } from "../types";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { RESTRICTED_EMAIL_PROVIDERS } from "utils/RESTRICTED_EMAIL_PROVIDERS";
import { isValidPhoneNumber } from "react-phone-number-input";
import isValidDate from "date-fns/isValid";
import { EMAIL_PATTERN_REGEX, URL_PATTERN_REGEX } from "utils/regex";

export function validateEmailAgainstRestrictedProviders(value?: string) {
  const host = value?.split("@")[1] || "";
  if (!host || RESTRICTED_EMAIL_PROVIDERS.includes(host)) {
    return false;
  }
  return true;
}

export function validateEmailAllowedDomains(
  allowedDomains: string[],
  value?: string
) {
  if (!value) return false;
  const host = value.split("@")[1];
  if (!allowedDomains.includes(host.toLowerCase())) return false;
  return true;
}

function validateUrl(url: string): boolean {
  try {
    let urlToTest = url;
    // There is a subtle bug here that is now part of the product. Because we prefix with https
    // before checking of the protocol is supported, we accept all URL protocols.
    if (!/^https?:\/\//i.test(urlToTest)) {
      urlToTest = `https://${urlToTest}`;
    }

    const parsedUrl = new URL(urlToTest);

    if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
      console.error("URL validation failed: Unsupported URL protocol");
      return false;
    }

    // TODO: INTRFCS-3621: Interfaces URL validation needs to be in sync with tables API
    if (!URL_PATTERN_REGEX.test(urlToTest)) {
      console.error(`URL validation failed: ${url}`);
      return false;
    }

    return true;
  } catch {
    console.error(`URL parsing failed: ${url}`);
    return false;
  }
}

export function buildValidationRules(config: FieldBlockConfig) {
  const rules: RegisterOptions = {};

  if (config.required && config.inputType !== "yes-no") {
    rules.required = config.required && `${config.label} is required.`;
  }

  switch (config.inputType) {
    case "text":
    case "textarea":
      rules.maxLength = {
        value: config.maxLength,
        message: `Should not be greater than ${config.maxLength} characters.`,
      };
      break;
    case "email":
      rules.pattern = {
        value: EMAIL_PATTERN_REGEX,
        message: `${config.label} is not valid.`,
      };
      rules.validate = (value: string) => {
        if (!value && !config.required) return true;

        if (
          config.blockFreeEmailProviders &&
          !validateEmailAgainstRestrictedProviders(value)
        )
          return "Please enter a business email address";

        if (
          config.restrictDomains &&
          !validateEmailAllowedDomains(
            config.allowedDomains?.toLowerCase().split(",") ?? [],
            value
          )
        )
          return "This email address is not allowed";
        return true;
      };
      break;
    case "number":
      if (config.min && parseInt(config.min)) {
        rules.min = {
          value: config.min,
          message: `Minimum value should be ${config.min}`,
        };
      }
      if (config.max && parseInt(config.max)) {
        rules.max = {
          value: config.max,
          message: `Maximum value should be ${config.max}`,
        };
      }
      rules.pattern = {
        value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
        message: `${config.label} should be a number.`,
      };
      break;
    case "yes-no":
      rules.validate = (value) => {
        if (config.required) {
          return value === true || value === false
            ? true
            : `${config.label} is required.`;
        }
        return true;
      };
      break;
    case "url":
      rules.validate = (value: string) => {
        if (!value && !config.required) return true;
        return validateUrl(value) ? true : `${config.label} is not valid.`;
      };
      rules.pattern = {
        value: URL_PATTERN_REGEX,
        message: `${config.label} is not valid.`,
      };
      break;
    case "multi-url":
      rules.validate = (value: string) => {
        if (!value && !config.required) return true;
        if (value.trim().endsWith(",")) {
          return `${config.label} contains a trailing comma.`;
        }
        // TODO: INTRFCS-3621: Interfaces URL validation needs to be in sync with tables API. For now, we know tables API trims and
        // uses comma separation.
        const urls = value.trim().split(",");
        for (const url of urls) {
          if (!validateUrl(url.trim())) {
            return `${config.label} is not valid at ${url.trim()}`;
          }
        }
        return true;
      };
      break;
    case "dropdown":
      rules.validate = (value?: string) => {
        if (!value && !config.required) return true;
        if (config.tableId) return true;
        const options = config.options
          ?.split("\n")
          .map((opt) => opt.trim())
          .filter((option: any) => !!option);
        if (config.multiSelect) {
          if (
            Array.isArray(value) &&
            value.every((v) => options?.includes(v))
          ) {
            return true;
          }
        } else {
          if (value && options?.includes(value)) {
            return true;
          }
        }
        return `${config.label} is not valid.`;
      };
      break;
    case "phone-number":
      rules.validate = (value: string) => {
        if ((!config.required && !value) || isValidPhoneNumber(value))
          return true;
        return `${config.label} is not valid.`;
      };
      break;
    case "checkbox":
      const message = config.label
        ? `${config.label} is required`
        : `${config.text} is required`;
      rules.required = config.required && message;
      break;
    case "date-picker":
      rules.validate = (value: Date) => {
        if ((!config.required && !value) || isValidDate(value)) return true;
        return `Date is not valid`;
      };
      break;
    case "currency":
      rules.validate = (value: string) => {
        if (!config.required && !value) return true;
        if (!isFinite(Number(value))) {
          return "Currency is not valid";
        }

        const amount = parseFloat(value);
        if (Number(amount.toFixed(config.decimals)) !== amount) {
          return `Only ${config.decimals} decimal places allowed.`;
        }
        return true;
      };
      break;
    default:
      break;
  }

  return rules;
}

export function calculateAllowedFileCount({
  maxFileCount,
  configuredFileCount,
}: {
  maxFileCount: number;
  configuredFileCount: number | undefined;
}) {
  // Ensure that we never exceed the max file count allowed by the plan
  return Math.min(configuredFileCount ?? maxFileCount, maxFileCount);
}
