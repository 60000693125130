import { blockType } from "./constants";
import { MediaBlockSchema } from "./schema";
import { Media, MediaBlock } from "./types";

export function copy(block: MediaBlock): MediaBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Media["create"] = ({ initialConfig = {} } = {}) => {
  return MediaBlockSchema.parse({
    type: blockType,
    config: {
      mediaType: "image",
      mediaSource: "url",
      height: 200,
      alignContent: "center",
      style: {
        width: "narrow",
        alignment: "center",
      },
      ...initialConfig,
    },
  });
};

export const map: Media["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...MediaBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
