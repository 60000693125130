import type { Operation } from "server/auth/types";

import type { StripePayment } from "../types";
import { z } from "zod";

export const allowed: StripePayment["allowed"] = (block) => {
  if (!block.config.id) return [];

  const operations: Operation[] = [
    {
      type: "query",
      path: "payments.getConnectedAccount",
      validator: z.never(),
    },
    {
      type: "mutation",
      path: "payments.createAccountLink",
      validator: z.object({
        termsVersion: z.string(),
      }),
    },
    {
      type: "mutation",
      path: "payments.createAccountSessionSecret",
      validator: z.never(),
    },
    {
      type: "query",
      path: "payments.getCheckoutSessionStatus",
      validator: z.object({
        sessionId: z.string(),
        stripePaymentId: z.literal(block.config.id),
      }),
    },
    {
      type: "mutation",
      path: "payments.triggerPaymentProcessed",
      validator: z.object({
        blockId: z.literal(block.config.id),
      }),
    },
  ];

  return operations;
};
