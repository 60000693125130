import type { SupportedTableFieldTypes } from "@/block-system/blocks/Table/lib/filterUnsupportedTableFields";
import { BlockType } from "@prisma/client";
import type { APIFieldLatest } from "server/services/tables/types/tables-latest";
import type { SortDirection } from "@tanstack/react-table";

export const blockType = "table-listing-block";
export const blockTypeDbName = BlockType.TableListing;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;

export const ALLOWED_TABLE_SORT_BY_FIELD_TYPES: APIFieldLatest["type"][] = [
  "text",
  "number",
  "datetime",
  "decimal",
  "string",
  "email",
] satisfies SupportedTableFieldTypes[];

/**
 * The name is arbitrary, but it should be unique across all columns.
 */
export const TABLE_ROW_ACTIONS_COLUMN_ID = "actions";

/**
 * `false` if the column is not sorted.
 * This values comes from `getIsSorted` of `@tanstack/react-table`.
 */
export const TABLE_NOT_SORTED_DIRECTION = false;

export type TableSortDirection =
  | typeof TABLE_NOT_SORTED_DIRECTION
  | SortDirection;

export const TABLE_VERTICAL_SCROLLBAR_OFFSET_CSS_VARIABLE_NAME =
  "--table-vertical-scrollbar-offset";

/**
 * The number is arbitrary.
 */
export const DEFAULT_PAGE_SIZE_NUMBER = 20;
export const MAX_PAGE_SIZE_NUMBER = 50;
export const MIN_PAGE_SIZE_NUMBER = 10;

export type TABLE_RECORDS_PAGINATION_PAGE_SIZE =
  | typeof MIN_PAGE_SIZE_NUMBER
  | typeof DEFAULT_PAGE_SIZE_NUMBER
  | typeof MAX_PAGE_SIZE_NUMBER;

export const TABLE_RECORDS_PAGINATION_PAGE_SIZES = [
  MIN_PAGE_SIZE_NUMBER,
  DEFAULT_PAGE_SIZE_NUMBER,
  MAX_PAGE_SIZE_NUMBER,
] satisfies TABLE_RECORDS_PAGINATION_PAGE_SIZE[];

/**
 * The number is arbitrary.
 * We have added it in attempt to reduce calls to the Tables API.
 */
export const TABLE_QUERY_STALE_TIME = 20_000;
