import type { SupportedCurrencies } from "block-system/blocks/Form/Field/types";
import { renderCurrencyIcon } from "block-system/utilities/currency-helpers";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { isNumber, isString } from "lodash";
import { APIField } from "server/services/tables/types/fields";
import type { APIUpdateRecordsResponse } from "server/services/tables/types/records";
import type { trpc } from "utils/trpc";
import { z } from "zod";

export const FILTERABLE_FIELD_TYPES = [
  "string",
  "multiple_string",
  "labeled_string",
  "multiple_labeled_string",
  "text",
  "multiple_text",
  // 'boolean',
  // 'multiple_boolean',
  // 'number',
  // 'multiple_number',
  // 'decimal',
  // 'multiple_decimal',
  // 'datetime',
  // 'multiple_datetime',
  "uuid",
  "multiple_uuid",
  // 'json',
  // 'multiple_json',
  // 'formula',
  // 'button_trigger_zap',
  // 'button_continue_zap',
  "email",
  "multiple_email",
  "link",
];

const linkedRecordSchema = z.object({
  label: z.string(),
  source: z.string(),
});

const multipleLinkSchema = z.array(
  z.object({
    link: z.string().url(),
    text: z.string(),
  })
);

export const isValidLinkedRecordFormat = (value: any) => {
  if (!Array.isArray(value) || value.length === 0) {
    return false;
  }

  return value.every((item) => linkedRecordSchema.safeParse(item).success);
};

import React from "react";

export function prettyValue(
  field: APIField,
  value: any
): string | number | React.ReactElement {
  if (value == null) return "";
  if (value === true) return "Yes";
  if (value === false) return "No";

  if (field.type === "datetime" && value) {
    const date = parseISO(value);
    const options = field.options || {};
    const dateFormat = `${options.date_format || "yyyy-MM-dd"} ${
      options.include_time ? options.time_format || "HH:mm" : ""
    }`;
    // Display in the same format that we see in Tables (Default to UTC timezone)
    return formatInTimeZone(date, options.timezone || "UTC", dateFormat);
  }

  if (field.type === "currency" && field.options?.currency_format) {
    return `${renderCurrencyIcon(
      field.options.currency_format as SupportedCurrencies
    )} ${value}`;
  }

  if (isNumber(value) || isString(value)) return value;

  if (typeof value === "object") {
    // links
    if (value.link && value.text) {
      return (
        <a
          style={{ textDecoration: "underline" }}
          href={value.link}
          title={value.text}
          target="_blank"
          rel="noopener"
        >
          {value.text}
        </a>
      );
    }

    // dropdown (labeled_string)
    if (value.label && value.value) {
      return value.label;
    }

    if (field.type === "formula") {
      if (value.status === "error") {
        return "";
      }
      return value.value;
    }

    if (field.type === "ai_formula") {
      return value?.value ?? "";
    }

    if (field.type === "multiple_linked_record") {
      if (Array.isArray(value) && value.length > 0) {
        if (isValidLinkedRecordFormat(value)) {
          return value.map(({ label }) => label).join(", ");
        }
      }

      return "";
    }

    if (field.type === "linked_record") {
      const parsedValue = linkedRecordSchema.safeParse(value);
      if (parsedValue.success) {
        return parsedValue.data.label;
      }
    }

    // multiple_*
    if (Array.isArray(value)) {
      if (value.length === 0) return "";
      if (field.type === "multiple_link") {
        const parsedValue = multipleLinkSchema.safeParse(value);
        if (parsedValue.success) {
          return (
            <span>
              {parsedValue.data.map((item, index) => (
                <React.Fragment key={index}>
                  <a
                    style={{
                      textDecoration: "underline",
                    }}
                    href={item.link}
                    title={item.text}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.text}
                  </a>
                  {index < parsedValue.data.length - 1 && ", "}
                </React.Fragment>
              ))}
            </span>
          );
        }
      }

      // multiple_labeled_string
      if (value[0].label && value[0].value) {
        return value.map(({ label }) => label).join(", ");
      }
    }
  }
  return JSON.stringify(value);
}

type UseUtils = ReturnType<typeof trpc.useUtils>;

type QueryRecordsCacheUpdater = Parameters<
  UseUtils["blockTable"]["queryRecords"]["setInfiniteData"]
>[1];

export function removeTableRecordsFromCache({
  recordIds,
}: {
  recordIds: string[];
}): QueryRecordsCacheUpdater {
  const updater: QueryRecordsCacheUpdater = (dataInCache) => {
    if (!dataInCache) {
      return {
        pages: [],
        pageParams: [],
      };
    }

    return {
      ...dataInCache,
      pages: dataInCache.pages.map((pageInCache) => ({
        ...pageInCache,
        data: pageInCache.data.filter((recordInCache) => {
          return !recordIds.includes(recordInCache.id);
        }),
      })),
    };
  };

  return updater;
}

export function updateTableRecordInCache({
  recordUpdates,
  updatedRecordId,
}: {
  recordUpdates: Partial<APIUpdateRecordsResponse["data"][number]["new"]>;
  updatedRecordId: string;
}): QueryRecordsCacheUpdater {
  const updater: QueryRecordsCacheUpdater = (dataInCache) => {
    if (!dataInCache) {
      return {
        pages: [],
        pageParams: [],
      };
    }

    return {
      ...dataInCache,
      pages: dataInCache.pages.map((pageInCache) => ({
        ...pageInCache,
        data: pageInCache.data.map((recordInCache) => {
          if (recordInCache.id === updatedRecordId) {
            return { ...recordInCache, ...recordUpdates };
          }

          return recordInCache;
        }),
      })),
    };
  };

  return updater;
}
